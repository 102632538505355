body {
    font-family: BoschSansRegular;
}

footer {
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    background-color: #fff;
    position: relative;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

ul.rb-legals {
    display: flex;
    list-style: none;
    margin-block-start: 0; /* Removing top margin added by the <ul> element */
    margin-block-end: 0; /* Removing bottom margin added by the <ul> element */
    padding-inline-start: 0; /* Removing left padding added by the <ul> element, as some additional padding can be seen for the footers in Mobile */
}

ul.rb-legals>li {
    margin-bottom: 0;
}

.rb-white-bar {
    z-index: 1;
    background-color: white;
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

/* mobile version */
@media only screen and (max-width: 767px) {
    .rb-white-bar {
        margin-bottom: -6px;
    }

    .rb-footer-content {
        flex-direction: column;
        margin: 20px 30px 20px 32px;
    }

    .rb-footer-content .rb-legals {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-top: 1rem;
    }

    .rb-legals li {
        margin: 10px 0;
    }

    .content-rb-page {
        margin-top: 72px;
    }
}

/* everything above */
@media only screen and (min-width: 768px) {
    .rb-footer-content {
        margin: 20px 30px 20px 15px;
    }

    .rb-footer-content a {
        margin-left: 30px;
        display: block;
    }

    .rb-legals {
        margin-left: auto;
    }
}

.rb-footer-content {
    display: flex;
    line-height: 1;
    font-weight: 700;
    font-size: .875rem;
    align-items: center;
}

.rb-footer-content a {
    font-size: .875rem;
    font-weight: 200;
    cursor: pointer;
}

h6 {
    color: #000000;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}

.paragraphMedium {
    color: #000000;
    background-color: #ffffff;
    font-family: BoschSansLight;
    font-size: 14px;
    line-height: 20px;
    font-weight: 200;
}

.paragraphSmall {
    color: #000000;
    background-color: #ffffff;
    font-family: BoschSansLight;
    font-size: 12px;
    line-height: 18px;
    font-weight: 200;
    letter-spacing: 0.03em;
}

.rb-background-logo {
    position: fixed;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-color: transparent;
}

.rb-logotype {
    position: absolute;
    width: 138px;
    height: 88px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 1.6rem 1.6rem;
    z-index: 1;
    left: 0;
}

@media only screen and (min-width: 768px) {
    .rb-image-banner {
        min-height: 72px;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        z-index: 1;
    }
}

.login-rb-header {
    position: relative;
    text-align: center;
}

/* mobile version */
@media only screen and (max-width: 767px) {
    .rb-background-logo {
        height: 72px;
    }

    .rb-logotype {
        position: relative;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-y: center;
        margin-left: 32px;
    }

    .login-rb-header {
        margin-top: 32px;
        margin-left: 0;
    }
}

/* you cannot apply these to evertyhing because the bosch theme is not covering every page */

.rb-application h3 {
    color: #000000;
    /* background-color: #ffffff; */
    font-family: BoschSansLight;
    font-size: 28px;
    font-weight: 200;
    line-height: 32px;
}

.rb-application h4 {
    color: #000000;
    /* background-color: #ffffff; */
    font-family: BoschSansLight;
    font-size: 24px;
    font-weight: 200;
    line-height: 28px;
}

.rb-application h6 {
    color: #000000;
    /* background-color: #ffffff; */
    font-family: BoschSansLight;
    font-size: 16px;
    font-weight: 200;
    line-height: 20px;
}

/*******************************************************************************
                            redesign the radio button
*******************************************************************************/

.radio label {
    margin-bottom: 0;
}

.radioButtonLabel {
    color: black;
    display: block;
    font-family: BoschSansRegular;
    font-size: 1rem;
    width: fit-content;
    position: relative;
    padding-left: 2.5rem;
    margin: 0.75rem 0rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radioButtonLabel input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


.checkmark {
    position: absolute;
    top: -0.1rem;
    left: 0.5rem;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #8A9097;
    border-radius: 50%;
}

.radioButtonLabel:hover input:enabled ~ .checkmark {
    background-color: #71767C;
}

/* When the radio button is checked, add a blue background */
.radioButtonLabel input:checked:enabled ~ .checkmark {
    background-color: #007BC0;
}

.radioButtonLabel input:checked:enabled + p {
    color: black;
}

.radioButtonLabel:hover input:checked:enabled ~ .checkmark {
    background-color: #005691;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioButtonLabel input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radioButtonLabel .checkmark:after {
    left: 25%;
    top: 25%;
    width: .75rem;
    height: .75rem;
    border-radius: 50%;
    background: white;
}

.radioButtonLabel.disabled {
    cursor: default;
}

.radioButtonLabel input:disabled ~ .checkmark {
    background-color: #EFEFF0;
}

.radioButtonLabel input:checked:disabled ~ .checkmark {
    background-color: #C1C7CC;
}

.radioButtonLabel input:checked:disabled ~ .checkmark:after {
    background-color: #8A9097;
}

.radioButtonLabel input[type="radio"]:disabled + p {
    color: #BFC0C2;
}

.radioButtonLabel input[type="radio"]:focus ~ .checkmark {
    box-shadow: 0 0 .5rem #005691;
    background-color: #004975;
}

.radioButtonLabel input[type="radio"]:disabled .rb-application label {
    color: #E0E2E5;
    background-color: transparent;
}

.radioButtonLabel input[type="radio"]:disabled .checkmark {
    background-color: #EFEFF0;
}

.radioButtonLabel input[type="radio"]:disabled .radioButtonLabel .checkmark:after {
    background-color: #BFC0C2;
}



/*******************************************************************************
                            redesign the select
*******************************************************************************/
.rb-select {
    position: relative;
    width: 100%;
}

.rb-select .label {
    display: none;
}

.rb-select select {
    display: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;

    font-family: BoschSansRegular;
    font-size: 1rem;
    width: 100%;
    height: 3rem;
    padding-left: 1rem;
    border-bottom: 1px solid #bfc0c2;
    outline: none;
    cursor: pointer;
}

.select-selected {
    display: flex;
    align-items: center;
    height: 3rem;
    font-size: 0.9rem;
    background-color: #e0e2e5;
    padding: 1rem 0 0 1rem;
    cursor: pointer;
}

.select-selected .select-label {
    display:none
}

/* tell IE not to show the arrow */
.rb-select select::-ms-expand {
    display: none;
}

.rb-select select:focus::-ms-value {
    background-color: transparent;
}

/* style the items (options), including the selected item: */
.select-items div {
    min-height: 2.5rem;
    padding: 1rem 0 0.5rem 1rem;
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    position: absolute;
    background-color: white;
    color: black;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    font-size: 0.9rem;
    border-bottom: 1px solid #bfc0c2;
    border-left: 1px solid #bfc0c2;
    border-right: 1px solid #bfc0c2;
}

.select-items.scrollable {
    max-height: 195px;
    overflow: hidden;
    overflow-y: scroll;
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:focus,
.select-items div:hover {
    background-color: #7FC6E7;
    color: white;
    outline: none;
}

.same-as-selected {
    background-color: #005691;
}

.rb-select .indicator::before {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
    font-size: 1.5rem;
    pointer-events: none;
}

.rb-select .select-items div > .highlight {
    font-weight: 600;
}

/*******************************************************************************
                            redesign the checkbox
*******************************************************************************/
.rb-autocomplete {
    position: relative;
    width: 100%;
}

.rb-autocomplete input {
    margin-bottom: 1px;
}

/* style the items (options), including the selected item: */
.autocomplete-items div {
    padding: 1rem 0 .5rem 1rem;
    cursor: pointer;
}

.rb-autocomplete .autocomplete-items {
    position: absolute;
    background-color: white;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border-bottom: 1px solid #bfc0c2;
    border-left: 1px solid #bfc0c2;
    border-right: 1px solid #bfc0c2;
}

.rb-autocomplete .autocomplete-items.scrollable {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}

.rb-autocomplete .autocomplete-items div {
    min-height: 2.75rem;
}

.rb-autocomplete .autocomplete-items div:hover {
    background-color: #7FC6E7;
    color: white;
}

.rb-autocomplete .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: #7FC6E7 !important;
    color: white;
}

.rb-autocomplete .indicator::before {
    position: absolute;
    right: 0.75rem;
    top: 1.5rem;
    font-size: 1.5rem;
    pointer-events: none;
}

.rb-autocomplete input:disabled ~ .indicator {
    color: #BFC0C2;
}

/*******************************************************************************
                            redesign the checkbox
*******************************************************************************/
.rb-slider {
    position: relative;
    display: flex;
    align-items: center;
    width: 3rem;
    height: 1.5rem;
}

.rb-slider > label {
    font-family: BoschSansRegular;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

}

/* Hide default HTML checkbox */
.rb-slider input {
    visibility: hidden;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bfc0c2;
    /* border: 1px solid #000000; */
    /* border-color: #000000; */
    -webkit-transition: .4s;
    transition: .4s;
}

input:hover + .slider {
    background-color: #7FC6E7;
}

.slider:before {
    position: absolute;
    content: "";
    height: 1.25rem;
    width: 1.25rem;
    left: .125rem;
    bottom: .125rem;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #005691;
}

input:focus + .slider {
    box-shadow: 0 0 1px #008ECF;
}

input:checked + .slider:before {
    left: 0;
    -webkit-transform: translateX(1.625rem);
    -ms-transform: translateX(1.625rem);
    transform: translateX(1.625rem);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.rb-slider.large {
    width: 48px;
    height: 24px;
}

.slider.round.large:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider.round.large:before {
    left: 0;
    -webkit-transform: translateX(1.6rem);
    -ms-transform: translateX(1.6rem);
    transform: translateX(1.6rem);
}

input:disabled + .slider.round.large {
    background-color: #cfd0d1;
}

input:disabled + .slider.round.large:before {
    left: 0;
    -webkit-transform: translateX(1.6rem);
    -ms-transform: translateX(1.6rem);
    background-color: #bfc0c2;
}

/*******************************************************************************
                            redesign the textfield
*******************************************************************************/
/* do this later as general redesign. currently it will breaks too much. */

/*******************************************************************************
                            redesign the link
*******************************************************************************/
.link {
    text-decoration: none;
    font-family: BoschSansRegular;
}

/* mobile version */
@media only screen and (max-width: 767px) {
    .link {
        font-size: 14px;
    }
}

/* everything above */
@media only screen and (min-width: 768px) {
    .link {
        font-size: 16px;
    }
}

.link .icon {
    width: 1rem;
    height: 1rem;
}

.link .icon:before {
    transition: transform 1s ease;
    transform: translateY(1px);
}

/* mobile version */
@media only screen and (max-width: 767px) {
    .link .icon {
        margin-left: .625rem;
    }
}

/* everything above */
@media only screen and (min-width: 768px) {
    .link .icon {
        margin-left: .375rem;
    }
}


/* mobile version */
@media only screen and (max-width: 767px) {
    .link:hover .icon:before {
        transform: translate(.125rem, 1px);
    }

}

/* everything above */
@media only screen and (min-width: 768px) {
    .link:hover .icon:before {
        transform: translate(.5rem, 1px);
    }
}

.primaryLink {
    font-size: 20px;
    color: #005691;
}

.primaryLink:hover {
    text-decoration: none;
    font-size: 20px;
    color: #7FC6E7;
}

.textLink {
    text-decoration: none;
    font-family: BoschSansRegular;
    color: #005691;
}

.textLink:hover {
    color: #7FC6E7;
}

/*******************************************************************************
                            redesign of buttons
*******************************************************************************/

.rb-button {
    font-family: BoschSansRegular, serif;
    border: 1px solid transparent;
    padding-right: 16px;
    padding-left: 16px;
    height: 48px;
    font-size: 1rem;
    cursor: pointer;
}

.rb-button:active {
    outline: none;
}

.rb-button:disabled {
    cursor: not-allowed;
}

.rb-button > .rb-icon {
    margin-right: 0.3em;
    font-size: 1.35em;
}

.rb-button--primary {
    border-color: #005691;
    background-color: #005691;
    color: #fff;
}

.rb-button--primary:hover:not(:disabled) {
    border-color: #7fc6e7;
    background-color: #7fc6e7;
}

.rb-button--primary:active:not(:disabled) {
    outline: 0;
    border-color: #008ecf;
    background-color: #008ecf;
}

.rb-button--primary:disabled {
    border-color: #bfc0c2;
    background-color: #bfc0c2;
}

@media (hover: none) {
    .rb-button--primary:hover {
        border-color: #005691;
        background-color: #005691;
    }
}

.rb-button--secondary {
    background-color: transparent;
    color: #005691;
}

.rb-button--secondary:hover {
    color: #7fc6e7;
}

.rb-button--secondary:active {
    color: #008ecf;
}

.rb-button--secondary:disabled {
    color: #bfc0c2;
}

@media (hover: none) {
    .rb-button--secondary:hover {
        color: #005691;
    }
}

/*******************************************************************************
                            define grid
*******************************************************************************/

/* @media (min-width: ) */

/*******************************************************************************
                            design modal
*******************************************************************************/
/* The Modal (background) */
.modal {
    /*Hidden by default*/
    display: none;
    /* Stay in place */
    position: fixed;
    /* Sit on top */
    z-index: 2;
    left: 0;
    top: 0;
    /* Full width */
    width: 100%;
    /* Full height */
    height: 100%;
    /* Enable scroll if needed */
    overflow: auto;
}

/* Modal Content/Box */
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: 10rem auto;
    padding: 4rem 2rem 2rem 2rem;
    text-align: center;
}

.modal-head {
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: calc(0px - 2.5rem);
    left: calc(50% - 2.5rem);
}

.modal-content .modal-icon::before {
    position: absolute;
    font-size: 80px;
    color: orange;
    left: calc(50% - 40px);
    top: -2.5rem;
}

.modal-content .buttons-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

/* mobile version */
@media only screen and (max-width: 767px) {
    .modal-content {
        margin-bottom: 0;
    }

    .modal-content .buttons-wrapper {
        justify-content: center;
        align-items: center;
    }
}

/* everything above */
@media only screen and (min-width: 768px) {
    .modal-content {
        width: 30rem;
    }

    .modal-content .buttons-wrapper {
        justify-content: center;
    }
}

.modal-content .buttons-wrapper input {
    width: auto;
}

ul.consent {
    margin-left: 20px;
    list-style-type: square;
}

/* Shared stuff */
.card-rb {
    display: flex;
    flex-direction: column;

    background-color: #fff;
    z-index: 1;
}

/* mobile version */
@media only screen and (max-width: 767px) {
    .card-rb {
        width: 100%;
        height: 100%;
        padding: 32px;
        padding-top: 0;
    }
}

/* everything above */
@media only screen and (min-width: 768px) {
    .card-rb {
        width: 600px;
        margin: 55px;
        padding: 32px;
    }
}


.card-rb #kc-page-title {
    font-family: BoschSansBold;
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 1.4;
}

.contentWrapper {
    margin-top: 2rem;
}

.content-rb-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.h1-formatted {
    text-align: center;
    font-size: 32px;
    line-height: 38px;
    font-weight: 200;
    font-family: BoschSansLight;
    letter-spacing: -0.01rem;
}
.description {
    margin-bottom: 1rem;
}

.btn-full-width {
    width: 100%;
    margin: 0 auto;
}

.btn-70-width {
    width: 70%;
    margin: 0 auto;
}

.p-idp-login {
    color: black;
    text-align: left;
    padding-top: 5px;
}

.btn-30-margin {
    width: 70%;
    margin: 0px auto 30px auto;
}

/*******************************************************************************
                            Locale
*******************************************************************************/

.uh-kc-locale-wrapper-div {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;
}

.uh-kc-locale-dropdown-div {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-left: auto;
    max-width: 140px;
}

.uh-kc-locale-dropdown-div ul {
    display: none;
}

.uh-kc-locale-dropdown-div:hover ul,
.uh-kc-locale-dropdown-div:focus-within ul{
    /*display: block; must not be set, as Keycloak is setting this attribute via menu-button-links.js especially for keyboard input. Otherwise escape button does not work.*/
    background-color: var(--uh-dropdown-backgroundColor);
    position: absolute;
    z-index: 999999999;
    margin-top: 1.5rem;
    max-height: 300px;
    min-width: 140px;
    max-width: 180px;
    padding: 0 0 0 0;
    overflow-y: auto;
    overflow-x: hidden;
}

button#kc-current-locale-link::after {
    font-family: 'bosch-icon';
    content: '\e147';
    margin-left: 6px;
}

.uh-language-switch1-locale-dropdown li {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 0 0;
    padding: 6px 12px 6px;
}

.uh-language-switch1-locale-dropdown li > a {
    color: var(--uh-dropdown-color);
    display: flex;
    height: 100%;
    width: 100%;
}

.uh-language-switch1-locale-dropdown li:hover,
.uh-language-switch1-locale-dropdown li:focus-within {
    background-color: var(--uh-dropdown-backgroundColor-hover);
}

.uh-language-switch1-locale-dropdown li:hover > a,
.uh-language-switch1-locale-dropdown li:focus-within > a {
    color: var(--uh-dropdown-color-hover);
}

.uh-language-switch1-locale-dropdown a:hover,
.uh-language-switch1-locale-dropdown a:focus {
    text-decoration: none;
    outline: none;
}

.uh-button-group {
    margin-top: 1.5rem;
}